<template>
  <div>
    <div class="d-flex align-center justify-space-between b-bottom mb-6">
      <span class="d-block font-16 font-500">District</span>
      <base-select
        v-model="language"
        :items="$root.languages"
        placeholder="Language"
        :returnObject="false"
      ></base-select>
      <v-icon @click="close" size="20" color="primary">mdi-close</v-icon>
    </div>
    <v-form v-model="valid" @submit.prevent="submitHandler" ref="districtform">
      <div class="px-6 mt-4 mb-10">
        <v-row>
          <!-- name -->
          <v-col cols="12" sm="6" md="4" class="mt-0 pt-0 pb-0">
            <label class="font-13 grey--text">District Name</label>
            <base-input
              placeholder="District Name"
              v-model="obj.name[language]"
              :hideDetails="false"
            ></base-input>
          </v-col>

          <!-- upload icon  -->
          <v-col cols="5" md="3" class="py-0">
            <!-- upload new -->
            <label class="font-13 grey--text mt-0">Main Icon</label>
            <div class="upload-item whitebg">
              <div>
                <v-icon v-if="!obj.icon" color="grayicon"
                  >mdi-folder-multiple-image</v-icon
                >
                <img v-if="obj.icon" :src="obj.icon" class="grayicon" />
              </div>
              <v-file-input
                :key="obj.icon && obj.icon.name ? obj.icon.name : obj.icon"
                @change="uploadIcon"
                hide-details="true"
                :rules="[
                  () => {
                    return obj.icon != undefined;
                  }
                ]"
              ></v-file-input>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0 pb-0">
          <v-col cols="12" class="mt-0 pt-0 pb-0">
            <label class="font-15 black--text mt-4 d-block">Locations</label>
          </v-col>
          <v-col cols="12" sm="6" v-for="(location, i) in locations" :key="i">
            <v-checkbox
              class="font-12"
              hide-details="true"
              v-model="location.checked"
              :label="location.title"
              :key="location._id"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
      <v-col class="d-flex" cols="12">
        <!-- buttons -->
        <buttons-experience
          @cancel="close"
          @next="submitHandler"
          text="Submit"
          :isValid="valid"
          :key="valid"
        ></buttons-experience>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    paramID: {
      type: String,
      default: undefined
    }
  },
  components: {
    ButtonsExperience
  },
  data: () => ({
    obj: { name: {} },
    valid: false,
    language: "en",
    locations: []
  }),
  methods: {
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(`/districts/${this.paramID}`);
        if (data && data.district) {
          const item = data.district;
          this.obj = {
            name: item.name,
            icon: item.icon?.url,
            _id: item._id,
            locations: item.locations
          };
          this.locations.forEach((loc) => {
            loc.checked =
              item.locations?.some((c) => {
                return loc._id == (c._id || c);
              }) || false;
          });
        }
      }
    },
    async submitHandler() {
      //   send request
      let formData = new FormData();
      if (this.obj.newIcon) {
        formData.append("icon", this.obj.newIcon);
      }
      formData.append("name", JSON.stringify(this.obj.name));
      formData.append("country", this.$root.country.short);
      formData.append(
        "locations",
        JSON.stringify(
          [
            ...(this.obj.locations || []),
            ...this.locations.filter((location) => {
              return location.checked;
            })
          ].map((location) => {
            return location._id;
          })
        )
      );
      var res;
      if (this.obj._id) {
        res = await this.$axios.put("districts/" + this.obj._id, formData);
      } else {
        res = await this.$axios.post("districts", formData);
      }
      if (res.data) {
        this.$emit("close");
        this.$emit("reloadData");
        this.$store.dispatch("showSnack", {
          text: `Updated successfully`,
          color: "success"
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error"
        });
      }
    },
    async uploadIcon(file) {
      this.obj.icon = URL.createObjectURL(file);
      this.obj.newIcon = file;
    },
    async fetchLocations() {
      let { data } = await this.$axios.get("location");
      if (data) {
        this.locations = data.locations;
        this.locations.forEach((loc) => (loc.checked = false));
      }
    },
    close() {
      this.$emit("close");
    }
  },
  async created() {
    this.language = this.$root.language;
    await this.fetchLocations();
    await this.fetchItemByID();
  }
};
</script>
